.footer{
    &__main{
        background-color: $color-dark-bg;
        border-bottom: 5px solid $color-primary;
        padding: 20px 0;

        .footer__main-content.wrapper{
            margin-top: 0;
        }

        &-content{
            justify-content: flex-start;
            align-items: flex-start;

            @media(max-width: 600px){
                justify-content: center;
            }
        }

        .grid__item{
            margin-right: 21px;

            @media(max-width: 782px){
                width: 23%;
            }
            @media(max-width: 600px){
                width: 60%;
                margin-right: 0;
                h3{
                    width: 100%;
                    nav,ul{
                        width: 100%;
                    }
                }
            }
            @media(max-width: 400px){
                width: 90%;
            }
        }

        a{
            text-decoration: none;
            color: $color-text-light;
        }

        ul{
            margin-top: 40px;

            @media(max-width: 1100px){
                margin-top: 20px;
            }
            li{
                display: block;
                text-decoration: none;
                color: $color-text-light;
                transition: 0.3s linear;
                padding: 5px 0;
                margin: 5px 0;
                text-align: left;
                line-height: 1.4;
            }
        }

        .ico{
            margin-right: 10px;
        }

        &-copyR{
            display: flex;
            flex-wrap: wrap;
            color: white;
            justify-content: flex-start;
            margin-top: 20px !important;

            p, a{
                padding: 5px 30px;
                font-size: $fontSize-footer-small;
                letter-spacing: 1px;
                font-weight: 100;
            }

            a {
                margin-right: 208px;
                padding-left: 0;

                @media(max-width: 1024px){
                    margin-right: 0;
                    padding-left: 0;
                    padding: 5px 30px;
                }

                &:hover {
                    color: #007031;
                }
            }
        }

    }

    &__header{
        color: $color-text-light;
        font-weight: bold;
        padding-bottom: 10px;
        border-bottom: 2px solid $color-primary;
        letter-spacing: 2px;
        font-variant-ligatures: none;
    }

    &__nav{
        width: 100%;
        ul{
            li{
                line-height: 1;
            }
        }


        a{
            display: block;
            text-decoration: none;
            color: $color-text-light;
            transition: 0.3s linear;
            text-align: left;

            &:hover{
                color: $color-primary;
            }

        }
    }
    &__contact{
        ul{
            li{
                display:flex;
                align-items: center;
            }

        }
    }

}