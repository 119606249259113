.nav__main{
    padding-left: 0px;
    .menu-menu-container{
        background-color: $color-primary;
    }
    .main__nav{
        list-style: none;
        padding: 0;
        display: flex;
        justify-content: flex-start;

        & > .menu-item{

            border-right: 1px solid rgba($color: #FFF, $alpha: .25);
            background-repeat: no-repeat;
            background-position: right center;

            @media (max-width: 900px){
                background-position: center;
                padding: 5px 0;
            }


            &:nth-child(1){
                margin-left: 0;
                background-image: url(../img/ico-about.svg);
            }
            &:nth-child(2){
                background-image: url(../img/ico-promo.svg);
            }
            &:nth-child(3){
                background-image: url(../img/ico-shops.svg);
            }
            &:nth-child(4){
                background-image: url(../img/ico-contact.svg);
            }
            &:nth-child(5){
                background-image: url(../img/ico-eq.svg);
            }
            &:nth-child(6){
                background-image: url(../img/ico-job.svg);
            }
            &:nth-child(7){
                background-image: url(../img/ico-contact.svg);
            }
            &:last-child{
                border-right: none;
            }
        }
    }
    .menu-item:not(.lang-item){
        display: inline-block;
        width: calc(100% / 7);
        flex: 1 1 calc(100% / 7);


        &:last-child{
            margin-right: 0;
            width: auto;
            flex: 0 0 auto;
            padding: 0 10px;
        }

        a{
            display: block;
            padding: 10px 0;
            font-size: $fontSize-menu;
            color: $color-text-light;
            text-decoration: none;
            font-weight: 200;
            text-align: center;
            transition: all .2s;
        }


    }
    .current_page_item:not(.lang-item),  .menu-item:not(:last-child):hover{
        a{
            color: $color-secondary;
            box-shadow:inset 0 0 5px 2px rgba($color: #000, $alpha: .25)
        }
    }




    .sub-menu{
        position: absolute;
        display: none;
        padding: 2px 5px;
        background-color: $color-primary;
        left: 0px;
        z-index: 900;

        li{
            display: block;
            margin: 0 5px;

            a{
                box-shadow: none;
            }

        }
    }
    .pll-parent-menu-item{
        position: relative;

        &:hover{
            .sub-menu{
                display: block;
            }
        }
    }

    @media(max-width: 900px){
        padding-left: 0;

        .menu-menu-container{
            position: relative;
            transition: all .3s;
            height: 0;
            z-index: 1000;
            overflow: hidden;

            &.open{
                height: 100%;
                overflow: visible;
            }
        }

        .main__nav{
            flex-wrap: wrap;
            padding-top: 20px;

            .menu-item{
                width: 100%;
                flex: 1 1 100%;

                &:hover{
                    a{
                        box-shadow: none;
                    }
                }

                &:last-child{
                    width: 100%;
                    flex: 1 1 100%;
                }

                &.current_page_item{
                    a{
                        box-shadow: none;
                    }
                }

                a:hover{
                    box-shadow: none;
                }

            }

            .sub-menu{

                width: 100%;
                border-top: 1px solid $color-secondary;
                padding: 0 5px;

                li{
                    margin: 0;
                }
            }
        }
    }

}

.mobile__toggle{
    background-color: transparent;
    border: 0;
    display: inline-block;
    padding: 0;
    @include centerX;

    @media(min-width: 900px){
        display: none;
    }
}

.mobile__toggle__button{
    width: 35px;
    height: 30px;
    padding: 10px;
    position: relative;
    cursor: pointer;
    display: inline-block;
  }
  .mobile__toggle__button span{
    background-color:$color-primary;
    position: absolute;
    border-radius: 2px;
    transition: .3s cubic-bezier(.8, .5, .2, 1.4);
    width:100%;
    height: 4px;
    transition-duration: 500ms
  }
  .mobile__toggle__button span:nth-child(1){
    top:0px;
    left: 0px;
  }
  .mobile__toggle__button span:nth-child(2){
    top:13px;
    left: 0px;
  }
  .mobile__toggle__button span:nth-child(3){
    bottom:0px;
    left: 0px;
  }
  .mobile__toggle__button:not(.clickMenu):hover span:nth-child(1){
    transform: rotate(-3deg) scaleY(1.1);
  }
  .mobile__toggle__button:not(.clickMenu):hover span:nth-child(2){
    transform: rotate(3deg) scaleY(1.1);
  }
  .mobile__toggle__button:not(.clickMenu):hover span:nth-child(3){
    transform: rotate(-4deg) scaleY(1.1);
  }
  .clickMenu span:nth-child(1){
    transform: rotate(45deg);
    top: 13px;

  }
  .clickMenu span:nth-child(2){
    transform: scale(.1);

  }
  .clickMenu span:nth-child(3){
    transform: rotate(-45deg);
    top: 13px;
  }

  #menu-item-52{
      display: none;
  }