.home{
    &__bg{
        background-color: $color-ligt-bg;
    }

    .wrapper{
        margin-top: -60px;

        @media (max-width: 900px){
            margin-top: -10px;
        }
    }

    .information{

        margin-top: 40px;
        margin-bottom: 60px;

        a{
            text-decoration: none;
            color: $color-text-dark;
        }

        h3{
            font-size: $fontSize-regular;
            font-weight: bold;
        }

        .ico{
            width: 50px;
            height: 50px;
            margin-bottom: 10px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            @include centerX();
        }

        .grid__item{
            display: block;
        }
    }

    .promotion__grid{
        .grid__default {
            width: 100%;
        }

        .grid__item{
            background-color: white;
            &__title{
                background-color: $color-primary;
                color: $color-text-light;
                display: flex;
                align-items: center;
                justify-content: center;
                align-content: center;

                span{
                    font-size: 12px;
                    padding: 0 5px;
                }


                h2{
                    line-height: 1.6;
                }

            }
            &.onlyimage {
                background-color: transparent;
                z-index: 2;

                a {
                    width: 100%;
                    cursor: zoom-in;
                }

                .promotion__thumb {
                    height: 401px;
                    background-size: contain;
                }

                .desc {
                    display: none;
                }

                .desc, .promotion__price {
                    display: none;
                }
            }
        }

        .promotion__price{
            display: flex;
            background-color: $color-primary;
            color: $color-text-light;
            padding: 5px 15px;
            justify-content: center;
            align-items: center;
            width: 100%;
            flex-wrap: wrap;

            h4{
                display: inline-block;
                margin: 0 4px;
                padding: 5px ;
            }

            &--old{
                    text-decoration: line-through;

            }

            &--new{
                    font-size: $fontSize-footer-big;
                    font-weight: bold;

                    @media (max-width: 900px){
                        font-size: 1.5rem;
                    }
            }
            span{
                width: 100%;
                font-size: 0.875rem;
            }
        }

        .newspaper{
            width: 50%;
            margin: 20px 0;
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;

            a{
                //width: 100%;
                //height: 100%;

                img{
                    width: 100%;
                    height: auto;
                }
            }

            &__content{
                width: 97.2%;
                height: auto;
                background-size: cover;
                background-repeat: no-repeat;

            }

        }
        .promotions__sec__grid{
            width: 48.66%;

            .grid__item{
                width: 47.3%;
            }
        }

        @media(max-width: 782px){
            .promotions__sec__grid{
                order: 2;
                width: 100%;
                .grid__item{
                    width: 20.3%;
                }
            }
            .newspaper{
                width: 100%;
                order: 1;
                justify-content: center;

                &__content{
                    max-width: 360px;
                }
            }
            .other-posts{
                order: 3;
            }

        }

        @media(max-width: 600px){
            .promotions__sec__grid{
                .grid__item{
                    width: 45%;
                }
            }
        }

        .other-posts{
            width: 100%;
            .hide{
                display: none;
            }


        }

        .button__load-more{
            width: 100%;
            cursor: pointer;
            .see-more-offers{
                width: 100%;
                font-size: $fontSize-regular;
                text-align: center;
                padding: 20px;
                background-color: white;
                border: 0;
                cursor: pointer;
            }
        }

    }

    .promotion__desc{
        position: relative;
        h4{
            text-align: center;
            font-size: $fontSize-footer-regular;
            color: rgba($color: #000000, $alpha: 0.6);

        }

    }

    .promotion-in-shops{
        &__title{
            margin: 7px 0;
            padding: 0 5px;
            font-style: italic;


        }

        &__one-shop{
            padding: 0 5px;
        }
        &__list{
            color: rgba($color: #000000, $alpha: 0.6);
            font-size: $fontSize-footer-regular;
            text-align: left;
            padding-top: 5px;
            max-height: 200px;
            overflow-y: auto;
            background-color: white;
            position: absolute;
            z-index: 900;
            bottom: 0;
            left: 0;
            width: 100%;
            box-sizing: border-box;
            transform: translateY(100%);
            transition: .3s ease-out;
            opacity: 0;
            box-sizing: border-box;

            &.active{
                transform: translateY(0%);
                opacity: 1;
                bottom: 100%;
                border: 1px solid $color-primary;
                border-left: 0;
                border-right: 0;
            }


        }

    }
    .shop__list__item{
        padding: 3px 7px;
        &:hover{
            background-color: rgba($color: #000000, $alpha: 0.1);
        }
    }
    .shop__list__allShops{
        font-style: italic;
        margin: 7px 0;
    }
    .show__shops-list{
        padding: 10px 5px;
        cursor: pointer;
        position: relative;
        transition: .2s ease-out;


        &:hover{
            background-color: rgba($color: #000000, $alpha: 0.1);

        }

        span{
            font-size: $fontSize-footer-small;
            color: $color-primary;
        }
    }

}

#search__promotions__form{
    width: 100%;
    display: flex;

    @media(max-width: 782px){
        justify-content: space-around;
    }
    @media(max-width: 400px){
        flex-wrap: wrap;
    }

    .button__submit{
        width: 23%;
    }

    #search__shop{
        width: 48.66%;
        padding: 9px 6px;
        margin-right: 2.73%;
        border: 1px solid rgba(0,0,0,.4);
        transition: .2s linear;

        @media(max-width: 782px){
            width: 45%;
            margin-right: 0;
        }

        @media(max-width: 400px){
            width: 100%;
        }

        &:active, &:focus{
            border-color: $color-primary;
        }

        &.error{
            border: 1px solid rgba(198, 0, 0, 0.8);
            box-shadow: 0 0 3px rgba(198, 0, 0, 0.8);
        }


    }

    #search__category{
        padding: 9px 6px;
        margin-right: 2.73%;
        border: 1px solid rgba(0,0,0,.4);
        transition: .2s linear;
        width: 23%;

        @media (max-width: 400px){
            width: 100%;
            margin-right: 0;
        }
    }
    .button{
        @media(max-width: 782px){
            width: 45%;
        }
        @media(max-width: 400px){
            width: 100%;
            margin-top: 20px;
            padding: 10px;
        }
     }
}