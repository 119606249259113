.slider{
    &__header{
        height: 400px;
        position: relative;


        &.swiper-container{
            margin: 20px 0;
        }

        &__item{
            background-size: cover;
            background-position: right center;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 100%;
        }

        .slider__content__{
            &wrapper{
                display: inline-block;
                padding: 20px 120px;
                color: $color-text-light;

            }
            &title{
                font-size: $fontSize-h1;
                font-weight: bold;
                text-align: center;
                line-height: 1;
            }

            &price{
                font-size: $fontSize-superBig;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 1;

            }
            &btn{
                color: $color-text-dark;
                background-color: $color-secondary;
                text-decoration: none;
                padding: 10px 25px;
                display: block;
                width: 95%;
                text-align: center;
                font-weight: 600;
                margin: 10px 0;
            }
            &from{
                font-size: 1.438rem;
            }
            &text{
                &--1{
                    font-size: 1.5rem;
                    font-weight: 800;
                    margin: 5px 0;
                }
                &--2{
                    font-size: 0.9375rem;
                    margin: 5px 0;
                }
            }
        }
        .price{

            &__wrapper{
                display: flex;
                flex-wrap: wrap;
                text-align: center;
                padding: 0 15px;
            }
            &__rest{
                font-size: $fontSize-h1;
                font-weight:200;
                width: 100%;
                border-bottom: 1px solid $color-text-light;

            }
            &__for{
                width: 100%;
                font-size: $fontSize-h1;
            }
        }

        .swiper-pagination{
            position: absolute;
            display: inline-block;
            width: initial !important;
            top: 50%;
            transform: translateY(-50%);
            left: 20px !important;

            &-bullet{
                display: block;
                background-color: transparent;
                border: 2px solid white;
                width: 15px;
                height: 15px;
                margin: 10px !important;

                &-active{
                    background-color: white;
                }
            }
        }
    }

}

.swiper-thumbnails{
    position: absolute;
    left: 0;
    top: -100px;
    height: 100px;
    overflow: visible !important;

    &.swiper-container{
        box-sizing: border-box;
        width: 500px;
        margin: 0;
        margin-left: 50px;

        .swiper-slide{
            background-size: cover;
            background-position: 240px center;
        }
        .swiper-slide-active{
            border-top: 5px solid $color-primary;
            transform: scale(1.05);
            z-index: 400;
        }

        .swiper-wrapper{
            transform: translateX(0) !important;
        }
    }

    &-item{
        position: relative;
        background-size: cover;
        border-top: 0;
        z-index: 100;
        cursor: pointer;
    }


}

@media (max-width: 900px){
    .swiper-thumbnails{
        top: -40px;

        &.swiper-container{
            margin: 0 auto;

            .swiper-wrapper{
                justify-content: center;
            }
        }


    }

}

@media (max-width:782px){
    .swiper-thumbnails{
        display: none;
    }
}

@media (max-width: 400px){
    .slider__header{
        .slider__content__wrapper{
            padding: 20px 10px;
        }

    }
}

