@font-face {
    font-family: 'MyriadPro';
    src: url('../fonts/MYRIADPRO-REGULAR.otf');
    font-weight: normal;
  }

@font-face {
    font-family: 'MyriadPro';
    src: url('../fonts/MYRIADPRO-LIGHT.otf');
    font-weight: 200;
}

@font-face {
    font-family: 'MyriadPro';
    src: url('../fonts/MYRIADPRO-SEMIBOLD.otf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'MyriadPro';
    src: url('../fonts/MYRIADPRO-SEMIBOLDIT.otf');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'MyriadPro';
    src: url('../fonts/MYRIADPRO-BOLD.otf');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'MyriadPro';
    src: url('../fonts/MYRIADPRO-BOLDIT.otf');
    font-weight: 800;
    font-style: italic;
}