.wpcf7-form{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media(max-width: 782px){
        justify-content: center;
    }

    .form{
        &__site{
            width: 50%;
        }

        &__ads{
            width: 50%;
            padding-top: 10px;
        }

        @media(max-width: 782px){
            &__site, &__ads{
                width: 95%;
            }
        }
    }

    .input__wrapper{
        position: relative;
        margin-top: 5px;
        label{
            position: absolute;
            top: 39px;
            left: 20px;
            transition: .2s ease-out;
            color: rgba($color: #000000, $alpha: 0.5);
            font-size: $fontSize-footer-small;

            &.active{
                top: 0;
                left: 0;
                color: rgba($color: #000000, $alpha: 1);
                font-size: $fontSize-footer-regular;
            }
        }


    }
    input{
        background-color: transparent;
        border: 1px solid rgba($color: #000000, $alpha: 0.5);
        padding: 15px 20px;
        margin: 5px 0;

        @media(max-width:400px){
            width: 100%;
        }

        &:focus{
            border: 1px solid $color-primary;
        }

        &[type='submit']{
            background-color: $color-primary;

            &:hover{
                background-color: transparent;
                color: $color-primary;
            }
        }
    }

    textarea{
        background-color: transparent;

        &:focus{
            border: 1px solid $color-primary;
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus{
        -webkit-text-fill-color: $color-primary;
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    .wpcf7-list-item{
        margin-left: 0;
        margin-top: 20px;
        padding: 10px 0;
        span{
            font-size: $fontSize-small;
        }
    }

    label[for="file"]{
            font-size: $fontSize-footer-small;
            color: $color-text-dark;
            background-color: transparent;
            padding: 18px 30px;
            padding-left: 50px;
            display: inline-block;
            cursor: pointer;
            border: 1px solid $color-primary;
            width: 100%;
            max-width: 350px;
            position: relative;
            margin-bottom: 40px;

            &::before{
                content: '';
                position: absolute;
                width: 20px;
                height: 20px;
                left: 15px;
                top: 13px;
                background-image: url(../img/add_btn.svg);
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }
    }
    #file {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;

    }

    .button__submit{
        position: relative;
        margin-left: 250px;
        margin-top: 30px;

        @media(max-width: 782px){
            margin-left: 270px;
        }

        @media(max-width:400px){
            margin-left: auto;
        }
    }

}