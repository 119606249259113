.grid{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 782px){
        justify-content: space-around;
    }

    &__space{
        &--around{
            justify-content: space-around;
        }
    }

    &__default{
        .grid__item{
            width: 23%;

            @media (max-width: 782px){
                width: 45%;
            }
            @media (max-width: 400px){
                width: 90%;
            }
        }
    }
    &__small{
        .grid__item{
            width: 15%;

            @media (max-width: 782px){
                width: 40%;
            }
        }
    }

    &__item{
        text-align: center;
        margin: 20px 0;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        height: auto;

        .thumb{
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 240px;
            width: 100%;

            @media (max-width: 900px){
                height: 150px;
            }

            @media (max-width: 782px){
                background-size: contain;
            }
        }

        .desc{
            width: 100%;
            font-size: $fontSize-regular;
            color: $color-text-dark;
            margin: 7px 0;
            padding: 2px 5px;

            h3{
                font-size: $fontSize-regular;
            }
        }
    }

    &__wide{
        .grid__item{
            width: 50%;
            text-align: left;

            &__desc{
                font-size: $fontSize-small;
            }
        }

        &--def{
            > .grid{
                width: 100%;
            }
            .grid__item{
                margin: 0;
            }
            .item__thumb{
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
            }
            .grid:nth-child(even){
                .item__thumb{
                    order: 2;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;

                    @media(max-width: 600px){
                        order: 1;
                    }
                }

                .item__content__wrapper{
                    order: 1;

                }
            }

            .item__content__wrapper{
                align-content: center;
                justify-content: center;
                padding: 60px;
                box-sizing: border-box;
                @media(max-width: 900px){
                    padding: 30px;
                }
                @media(max-width: 600px){
                    width: 100%;
                }
            }

            .item__thumb{
                @media(max-width: 600px){
                    width: 100%;
                    min-height: 100px;
                }
            }

            .item__content{

                h2{
                    color: $color-primary;
                    font-weight: 600;
                }
                p{
                    font-size: $fontSize-small;
                    line-height: 1.4;
                }

                ul{
                  columns: 2;
                  li{
                      position: relative;
                      padding-left: 5px;
                      margin: 5px 0;
                      &::before{
                          content: '-';
                          position: absolute;
                          top: 0;
                          left: -5px;
                      }
                  }
                }

                .title__wrapper{
                    margin-bottom: 20px;
                    padding-left: 20px;
                    border-left: 8px solid $color-primary;
                }
            }
        }
    }
}