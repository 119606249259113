.contact{

    h1{
        width: 100%;
        text-align: left;
        margin-bottom: 30px;
    }

    &__form{
        &__wrapper{
            width: 48%;
            align-items: flex-start;
            align-content: flex-start;

            @media(max-width: 782px){
                width: 100%;
                order: 2;
            }

            .wpcf7{
                width: 100%;
            }
            .wpcf7-response-output{
                border: 0;
            }
            .wpcf7-mail-sent-ok{
                color: $color-primary;
            }
            .wpcf7-validation-errors, .wpcf7-acceptance-missing{
                opacity: 0;
                display: none;
            }

        }
    }

    &__map{
        width: 100%;
        height: 400px;
        background-color: #c3c3c3;
        &__wrapper{
            width: 48%;
            display: flex;
            padding-top: 120px;
            @media(max-width: 782px){
                width: 100%;
                order: 2;
            }
        }
    }

    .form__inputs__wrapper{
        width: 100%;
        display: flex;
        justify-content: space-between;

        .input__wrapper{
            width: 47%;

            input{
                width: 100%;
                padding: 10px 20px;


            }
            label{
                top: 34px;
                &.active{
                    top: 0;
                }
            }

        }
    }
    .input__wrapper{
        width: 100%;
        textarea{
            width: 100%;
            height: 60px;
        }
    }
    .btn__wrapper{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        @media(max-width: 400px){
            flex-wrap: wrap;
        }

        .button{
            order: 1;
            padding: 10px 20px;
            @media(max-width: 400px){
                width: 100%;
            }
        }
    }

    &__company__info{
        h3{
            text-align: left;
            font-size: $fontSize-regular;
            font-weight: 600;
            margin-top: 10px;
            line-height: 40px;
        }
        h4{
            text-align: left;
            margin-bottom: 14px;
        }
        ul{
            columns: 2;
            column-gap: 65px;
            text-align: left;
        }

        li{
            padding: 5px 0;
        }
    }

    &__info__wrapper{
        margin: 60px 0;

        @media(max-width: 782px){
            width: 100%;
            order: 3;
        }
    }
    .contact__info__item{
        border-top: 1px solid rgba($color: #000000, $alpha:.4);
        width: 48%;
        padding: 15px 0;
        justify-content: flex-start;
        h2{
            width: 40%;
            min-width: 250px;
            text-align: left;

            @media(max-width: 900px){
                min-width: 180px;

            }
        }
        li{
            text-align: left;
            padding: 5px 0;
            flex-basis: 1;
        }
    }

}
.map-info__window{
    border-radius: 13px;

    .map-info__logo{
        height: 50px;
        width: 100%;
        background-image: url(../img/logo-zacheta.svg);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.contact__company__info{
    margin-top: -20px;

    @media (max-width: 400px){
        margin-top: 10px;
    }
}

.email__hidden{
    cursor: pointer;
}

ol, ul {
    cursor: default;
}

.g-recaptcha {
    margin-top: 30px;
}