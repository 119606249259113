
$color-primary: #007031;
$color-secondary: #FFF200;

$color-text-dark: #333333;
$color-text-light: #ffffff;

$color-dark-bg: #3C4247;
$color-ligt-bg: #f6f6f6;

$color-alert: #FF1D25;