.about-us__page{
    .page__title{
        display: inline-block;
        left: 0;
        transform: translateX(0);
        margin-bottom: 30px;
        border-bottom-width: 3px;
    }

    .grid__item:nth-child(odd){
        padding-right: 20px;
        @media(max-width: 782px){

            width: 100%;
        }
    }
    .grid__item:nth-child(even){
        padding-left: 20px;
        @media(max-width: 782px){

            width: 100%;
        }
    }

    .grid__ilu{
        align-items: stretch;
        align-content: stretch;
        min-height: 150px;

        .grid__item{
            text-decoration: none;
            width: 50%;
            margin: 0;
            align-items: center;
            color: $color-text-dark;
            @media(max-width: 400px){
                justify-content: center !important;
                flex-wrap: wrap;
                padding-top: 5px;
                padding-bottom: 5px;

                h3{
                    width: 100%;
                    text-align: center !important;
                }

            }

            h3{
                font-weight: bold;
                font-size: $fontSize-menu;
            }
            a{
                text-decoration: none;
                color: $color-text-dark;
            }
            .ico{
                width: 65px;
                height: 65px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;

                @media(max-width: 1100px){
                    width: 45px;
                    height: 45px;
                }
                @media(max-width: 900px){
                    width: 25px;
                    height: 25px;
                }
            }
        }
        .grid__item:nth-child(1){
            justify-content: flex-end;
            border-bottom: 3px dotted rgba($color: #000000, $alpha: .15);
            border-right: 3px dotted rgba($color: #000000, $alpha: .15);
            .ico{
                order: 2;
                background-image: url('../img/ico-map-green.svg');
                margin-left: 10px;
                @media(max-width: 400px){
                    margin-left: 0;
                    order: 1;
                }
            }
            h3{
                order: 1;
                text-align: right

            }

        }
        .grid__item:nth-child(2){
            justify-content: flex-start;
            border-bottom: 3px dotted rgba($color: #000000, $alpha: .15);
            .ico{
                background-image: url('../img/ico-meat-green.svg');
                margin-right: 10px;
                height: 75px;
                width: 75px;
                @media(max-width: 1100px){
                    width: 55px;
                    height: 55px;
                }
                @media(max-width: 900px){
                    width: 35px;
                    height: 35px;
                }
                @media(max-width: 400px){
                    margin-right: 0;
                }
            }
            h3{
                text-align: left

            }
        }
        .grid__item:nth-child(3){
            justify-content: flex-end;
            border-right: 3px dotted rgba($color: #000000, $alpha: .15);
            .ico{
                order: 2;
                background-image: url('../img/ico-bread-green.svg');
                margin-left: 10px;
                height: 75px;
                width: 75px;
                @media(max-width: 1100px){
                    width: 55px;
                    height: 55px;
                }
                @media(max-width: 900px){
                    width: 35px;
                    height: 35px;
                }
                @media(max-width: 400px){
                    margin-left: 0;
                    order: 1;
                }
            }
            h3{
                order: 1;
                text-align: right;
            }
        }
        .grid__item:nth-child(4){
            justify-content: flex-start;
            .ico{
                background-image: url('../img/ico-star-green.svg');
                margin-right: 10px;
                @media(max-width: 400px){
                    margin-right: 0;
                }
            }
            h3{
                text-align: left

            }
        }
    }

}


.about-us__content{
    margin-top: 60px;

    .quotation__wrapper{
        max-width: 100%;
        overflow: hidden;
        width: 560px;
        margin-top: 15px;
        padding: 0 20px;
        box-sizing: border-box;

        @media(max-width: 1100px){
            width: 400px;
        }
        @media(max-width: 900px){
            width: 300px;
        }
        @media(max-width: 600px){
            width: 400px;
        }
        @media(max-width: 400px){
            width: 290px;
        }
    }



    .thanks__wrapper{
        margin-bottom: 30px;

        .item__content__wrapper{
            background-color: $color-primary;
            color: white;
            padding: 80px 60px;
            padding-left: 100px;
            @media(max-width: 1100px){
                padding-left: 60px;
            }
        }


        h2{
            color: white;
            margin-bottom: 40px;
        }
        p{
            position: relative;
            font-style: italic;

            &::before{
                content: '';
                display: block;
                position: absolute;
                width: 45px;
                height: 45px;
                background-image: url(../img/ico-quo.svg);
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                left: -65px;
                @media(max-width: 1100px){
                    width: 25px;
                    height: 25px;
                    left: -35px;
                }
            }
        }

        .item__thumb{
            display: block;

            img{
                max-height: 85%;
                height: auto;
                width: auto;
                max-width: 100%;
                position: relative;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
        }

    }




}