.jobs{

    &__header{
        margin-top: 20px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        h1{
            color: $color-text-light;
            padding: 140px 0;
            padding-bottom: 180px;
            font-weight: 600;
            letter-spacing: 2px;
            border-bottom: 0;
        }
    }

    &__content{
        h2{
            color: $color-primary;
            background-color: white;
            margin-top: -77px;
            position: relative;
            border-bottom: 3px solid $color-secondary;
            padding: 20px 40px;

            @media(max-width: 1366px){
                margin-top: -73px;
            }
            @media(max-width: 1100px){
                margin-top: -66px;
            }

        }
    }

    &__agreements{
        padding: 40px;

        p{
            margin: 30px 0;
        }
        em{
            font-size: $fontSize-small;
        }
    }

    &__form{
        border-top: 2px solid $color-primary;
        padding: 40px 0;
    }
}

.job{
    &__item{
        padding: 20px 40px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        position: relative;

        &:hover{
            background-color: rgba($color: #000000, $alpha: .05);
        }

        &__name{
            padding: 10px 0;
            cursor: pointer;

            h3{
                color: $color-primary;
            }

        }
        &__desc{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            h4{
                font-weight: bold;
                padding-bottom: 20px;
                padding-top: 30px;
            }

            li{
                padding-left: 15px;
                margin: 5px 0;
                position: relative;

                &::before{
                    content: '';
                    display: block;
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: $color-primary;
                    left: 0px;
                    top: 5px;

                    @media(max-width: 782px){
                        top: 2px;
                    }
                }
            }

            .gallery-job-slider{
                display: flex;
                height: 600px;
                width: 400px;
            }
            @media screen and (max-width: 724px) {
                .gallery-job-slider{
                    height: 250px;
                    width: 100%;
                }
            }

            .demo {
                width:450px;
                margin-top: 25px;
            }
            @media screen and (max-width: 724px) {
                .demo {
                    height: 250px;
                    width: 100%;
                }
            }

            .demo ul {
                list-style: none outside none;
                padding-left: 0;
                margin-bottom:0;
                height: 500px !important;
            }
            @media screen and (max-width: 724px) {
                .demo ul {
                    height: 200px !important;
                }
            }

            .demo li {
                display: block;
                float: left;
                margin: 0;
                margin-right: 6px;
                padding-left: 0;
                cursor:zoom-in;
                position: unset;
                height: 500px;

                &::before{
                    content: none;
                }
            }

            .demo img {
                display: block;
                max-width: 100%;
                height: 100%;
                margin-left: auto;
                margin-right: auto;
            }

            .lSAction > .lSPrev {
                left: 0px;
            }

            .lSAction > .lSNext {
                right: 0px;
            }

            .lSAction > .icon{
                color: #888;
                font-size: 72pt;
            }

            .lSAction > a {
                background-image: unset;
            }

            .lSSlide > li{
                padding-left: 50px;
            }

            .lSPager li{
                -webkit-filter: unset;
            }

            .lSPager li.active{
                -webkit-filter: unset;
            }

            .lSSlideOuter .lSPager.lSGallery {
                width: 100% !important;
                padding: 0 50px !important;
            }

            .col__{
                &1{
                    width: 48%;
                    @media (max-width: 900px){
                        width: 90%;
                    }
                }
                &2{
                    width: 48%;
                    @media (max-width: 900px){
                        width: 90%;
                        height: 250px;
                    }
                }
            }
        }
    }
}
.more_info_p{
    margin: 10px 0;
    font-size: $fontSize-small;
}

.social__wrapper{
    padding: 0 40px;
    margin-top: 40px;
    display: flex;
    align-items: center;

    p{
        display: inline;
        margin-right: 20px;
    }

    .a2a_default_style{
        display: inline-block;
    }

    .a2a_default_style span{
        border-radius: 50%;
        padding: 5px;
    }

    @media(max-width: 782px){
        margin-top: 20px;

        .a2a_default_style span{
            width: 25px;
            height: 25px;
        }
    }
}

#search__jobs__form{
    display: flex;
    flex-wrap: wrap;
    padding: 25px 40px;
    background-color: #f6f6f6;

    #search__shop{
        border: 1px solid rgba($color: #000000, $alpha: .4);
        padding: 7px 5px;
        width: 20%;
        min-width: 220px;
        margin-right: 50px;
        transition: .3s ease-out;

        &:active, &:focus{
            border-color: $color-primary;
        }
    }
}