.site__header{
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    align-items: center;

    @media(max-width: 900px){
        margin-top: 10px;
        justify-content: center;
    }


    .logos__wrapper{
        width: 20%;

        @media(max-width: 1400px){
            width: 25%;
        }

        @media(max-width: 900px){
            height: 100px;
            min-width: 250px;
        }

        &__link{
            display: flex;
            height: 100%;
            justify-content: space-between;
        }

        .logo{
            max-width: 150px;
            width: 45%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            margin: 0 10px;

            &__1{
                max-width: 230px;
                width: 55%;
                height: 90px;

            }
        }

    }

    .nav__main{
        width: 80%;

        @media (max-width: 1400px){
            width: 75%;
        }

        @media (max-width: 900px) {
            width: 100%;
        }
    }

    .slider__header{
        width: 100%;
    }
}