.shops__list{
    h1{
        display: block;
        text-align: center;
        margin-bottom: 60px;
    }

    &__grid{

        .grid__item{
            width: 32%;
            text-align: left;
            font-size: $fontSize-regular;
            font-weight: lighter;
            border: 1px solid rgba($color: #000000, $alpha: .4);
            padding: 10px 20px;
            box-sizing: border-box;

            @media (max-width: 600px){
                width: 45%;
            }
            @media (max-width: 400px){
                width: 85%;
            }
        }

    }

}
.shop-information{
    &__wrapper{
        li{
            margin: 5px 0;
        }
        strong{
            font-weight: normal;
            color: $color-primary;
            margin-bottom: 10px;
            display: inline-block;
        }
    }
}

.shops__finder{
    background-color: $color-ligt-bg;
    position: relative;
    .form__stage{
        padding: 20px 0;
        border-top: 1px solid rgba($color: #000000, $alpha: .4);

        p{
            font-weight: lighter;
            margin-right: 150px;
            position: relative;
            padding-left: 30px;
            min-width: 244px;

            @media (max-width:900px){
                margin-right: 20px;
            }
            @media (max-width: 782px){
                min-width: 90px;
            }
            @media (max-width: 600px){

                    width: 100%;

            }

        }

        .stage__number{
            position: absolute;
            color: $color-primary;
            display: block;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 1px solid $color-primary;
            text-align: center;
            left: -15px;
            line-height: 1.3;
            font-weight: 600;

            @media (max-width: 1366px){
                width: 28px;
                height: 28px;
            }
            @media (max-width: 1100px){
                width: 21px;
                height: 21px;
            }
            @media (max-width: 900px){
                display: none;
            }
        }

        .grid{
            justify-content: flex-start;
            align-items: center;
            @media(max-width: 600px){
                    justify-content: center;

            }
        }

        .location_input{
            border: 1px solid rgba($color: #000000, $alpha: .4);
            margin-right: 10px;
            padding: 10px;
            transition: .2s linear;
            width: 470px;


            &:active, &:focus{
                border-color: $color-primary;
            }
            @media (max-width: 600px){
                width: 45%;
                margin-top: 25px;
            }
        }

        .button{
            @media(max-width: 600px){
                width: 45%;
                margin-top: 25px;
                padding: 9px;
            }
        }

        .input__wrapper{
            min-width: 120px;

            @media (max-width: 782px){
                min-width: 90px;
            }
            @media (max-width: 600px){
                margin-top: 25px;
            }
            @media (max-width: 400px){
                margin-bottom: 10px;
            }
        }


    }


    &__map{
        height: 550px;

        strong{
            color: $color-primary;
            padding-top: 5px;
        }
    }


}
.shops__finder__map{
    overflow: hidden;
    .map-info{
        &__window{
            width: calc(100% + 20px);
        }
        &__address{
            li{
                margin: 2px 0;
            }
        }

        &__logo{
            margin-bottom: 10px;
        }


    }

}
.map-info{
    &__msgBox{
        position: absolute;
        left: 50%;
        display: inline-block;
        transform: translate(-50%);
        background-color: white;
        padding: 10px 30px;
        border: 1px solid rgba($color: #000000, $alpha: .4);
        bottom: 0;
        z-index: 100;
        font-size: $fontSize-main;
        color: rgba($color: #000000, $alpha: .6);
        transition: .3s ease-out;

        button{
            background-color: transparent;
            border: 1px solid rgba($color: #000000, $alpha: .4);
            @include centerX;
            padding: 10px 20px;
            margin-top: 20px;
            cursor: pointer;
        }
    }
}
#shops__finder__form{
    position: relative;
    z-index: 400;
    background-color: #f6f6f6;
}